import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  apiNewSignIn,
  apiSignIn,
  apiSignOut,
  apiSignUp,
} from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    // navigate(appConfig.unAuthenticatedEntryPath)
    window.location.href = appConfig.unAuthenticatedEntryPath;
    return null;
  };

  const handleReset = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    return null;
  };

  const signOut = async () => {
    await apiSignOut();
    handleSignOut();
  };

  const loginWithToken = async (values) => {
    try {
      const resp = await apiNewSignIn(values);
      if (resp.data) {
        const token = values;
        if (resp.data.data) {
          if (window.location.host.split(".")[0] === "ppmd") {
            if (resp.data.data.authority.includes("PPMD")) {
              resp.data.data.authority.push("ppmd menu");
              dispatch(onSignInSuccess(token));
              dispatch(
                setUser(
                  resp.data.data || {
                    avatar: "",
                    userName: "Anonymous",
                    authority: ["USER"],
                    email: "",
                  }
                )
              );
            } else {
              return {
                status: "failed",
                message: "Permission Denied",
              };
            }
          } else if (window.location.host.split(".")[0] === "system") {
            if (
              resp.data.data.authority.includes("System") ||
              resp.data.data.authority.includes("Admin")
            ) {
              resp.data.data.authority.push("system menu");
              dispatch(onSignInSuccess(token));
              dispatch(
                setUser(
                  resp.data.data || {
                    avatar: "",
                    userName: "Anonymous",
                    authority: ["USER"],
                    email: "",
                  }
                )
              );
            } else {
              return {
                status: "failed",
                message: "Permission Denied",
              };
            }
          } else if (window.location.host.split(".")[0] === "game") {
            if (
              resp.data.data.authority.includes("System") ||
              resp.data.data.authority.includes("Admin")
            ) {
              resp.data.data.authority.push("game menu");
              dispatch(onSignInSuccess(token));
              dispatch(
                setUser(
                  resp.data.data || {
                    avatar: "",
                    userName: "Anonymous",
                    authority: ["USER"],
                    email: "",
                  }
                )
              );
            } else {
              return {
                status: "failed",
                message: "Permission Denied",
              };
            }
          } else {
            return {
              status: "failed",
              message: "Subdomain tidak valid",
            };
          }
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "Success",
          message: "Berhasil Login",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const refresh = async (values) => {
    try {
      const resp = await apiNewSignIn(values);
      if (resp.data) {
        const token = values;
        dispatch(onSignInSuccess(token));
        if (resp.data.data) {
          if (window.location.host.split(".")[0] === "ppmd") {
            resp.data.data.authority.push("ppmd menu");
          } else if (window.location.host.split(".")[0] === "system") {
            resp.data.data.authority.push("system menu");
          }
          dispatch(setUser(resp.data.data));
        }
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const logoutOnly = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    loginWithToken,
    logoutOnly,
    refresh,
    handleReset,
  };
}

export default useAuth;
